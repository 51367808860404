import React from "react";
import Navbar from "../../Header/Navbar/Navbar";
import Footer from "../../Footer/Footer";
import HF2 from "../../Frames/HomeFrame/HFrame2/HF2";
import HF3 from "../../Frames/HomeFrame/HFrame3/HF3";
import HF7 from "../../Frames/HomeFrame/HFrame7/HF7";
import HF8 from "../../Frames/HomeFrame/HFrame8/HF8";
import Slider from "../../Slider/Slider";
import Banner from "../../Banner/Banner";

const Home = () => {
  return (
    <>
      <Banner />
      <Navbar />
      <HF2 />
      <HF3 />
      <Slider 
        title="İstanbul'da Satılık Gayrimenkuller" 
        apiEndpoint="https://api.guvenlisatkirala.com/api/advertisements/"
        categories={["DAİRELER", "İŞ YERLERİ", "ARSALAR"]}
        city="İstanbul"
      />
      <Slider 
        title="Ankara'da Kiralık Gayrimenkuller" 
        apiEndpoint="https://api.guvenlisatkirala.com/api/advertisements/"
        categories={["EVİNİ KİRAYA VER", "KONUT KİRALA", "OFİS KİRALA"]}
        city="Ankara"
      />
      <HF7 />
      <HF8 />
      <Footer />
    </>
  );
};

export default Home;