import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Buycard from "../Card/BuyCard/Buycard";
import {
  MainContainer,
  CardContainer,
  SSlider,
  SliderContainer,
  Lbutton,
  Rbutton,
  ButtonText,
  TextContainer,
  Heading,
  ButtonContainer,
  Button,
} from "./StyledSlider";
import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

const Slider = ({ title, apiEndpoint, categories, city }) => {
  const [cardsData, setCardsData] = useState([]);
  const [activeCategory, setActiveCategory] = useState(categories[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const productContainerRef = useRef(null);

  useEffect(() => {
    async function fetchAdvertisements() {
      setIsLoading(true);
      setError(null);
      try {
        console.log(`Fetching data for ${title} from ${apiEndpoint}`);
        const response = await axios.get(apiEndpoint, {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            category: activeCategory,
          },
        });
        console.log(`API Response for ${title}:`, response.data);

        if (response.headers["content-type"].includes("application/json")) {
          const advertisements = response.data;
          console.log(`Advertisements for ${title}:`, advertisements);

          if (advertisements.length === 0) {
            console.log(`No advertisements found for ${title}`);
            setError(`Veri bulunamadı: ${title}`);
          } else {
            const filteredData = advertisements.filter(ad => ad.location.toLowerCase().includes(city.toLowerCase()));
            const transformedData = filteredData.map((ad) => ({
              Pic: ad.images[0]?.image || " ",
              Pic1: ad.images[1]?.image || " ",
              Name: ad.dynamic_attributes.title,
              PPrice: ad.dynamic_attributes.price,
              Beds: ad.dynamic_attributes.rooms,
              Washs: ad.dynamic_attributes.bathrooms,
              SqArea: ad.dynamic_attributes.area_sqft,
              PArea: ad.dynamic_attributes.neighborhood,
              PCity: ad.location,
              UpTime: ad.created_at,
              Href: `/singleproperty/${ad.id}`,
              isNew: ad.is_new,
              isFeatured: ad.is_featured
            }));

            setCardsData(transformedData);
          }
        } else {
          console.error(`Expected JSON for ${title}, but received:`, response.data);
          setError(`Geçersiz veri formatı: ${title}`);
        }
      } catch (error) {
        console.error(`Error fetching advertisements for ${title}:`, error);
        setError(`Veri yüklenirken hata oluştu: ${title}`);
      } finally {
        setIsLoading(false);
      }
    }

    fetchAdvertisements();
  }, [apiEndpoint, activeCategory, title, city]);

  const btnpressprev = () => {
    const width = productContainerRef.current?.clientWidth || 0;
    if (productContainerRef.current) {
      productContainerRef.current.scrollLeft -= width;
    }
  };

  const btnpressnext = () => {
    const width = productContainerRef.current?.clientWidth || 0;
    if (productContainerRef.current) {
      productContainerRef.current.scrollLeft += width;
    }
  };

  if (isLoading) {
    return <div>Yükleniyor...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <MainContainer>
      <TextContainer>
        <Heading>{title}</Heading>
        <ButtonContainer>
          {categories.map((category) => (
            <Button
              key={category}
              onClick={() => setActiveCategory(category)}
              active={activeCategory === category}
            >
              {category}
            </Button>
          ))}
        </ButtonContainer>
        <SliderContainer>
          <Lbutton onClick={btnpressprev}>
            <ButtonText>
              <BiLeftArrowAlt />
            </ButtonText>
          </Lbutton>
          <SSlider ref={productContainerRef}>
            {cardsData.length > 0 ? (
              cardsData.map((card, index) => (
                <CardContainer key={index}>
                  <Buycard
                    Pic={card.Pic}
                    Pic1={card.Pic1}
                    Name={card.Name}
                    PPrice={card.PPrice.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' })}
                    SqArea={card.SqArea}
                    Beds={card.Beds}
                    Washs={card.Washs}
                    PArea={card.PArea}
                    PCity={card.PCity}
                    UpTime={new Date(card.UpTime).toLocaleDateString('tr-TR', { day: 'numeric', month: 'long' })}
                    Href={card.Href}
                    isNew={card.isNew}
                    isFeatured={card.isFeatured}
                  />
                </CardContainer>
              ))
            ) : (
              <div>Bu kategori için ilan bulunamadı.</div>
            )}
          </SSlider>
          <Rbutton onClick={btnpressnext}>
            <ButtonText>
              <BiRightArrowAlt />
            </ButtonText>
          </Rbutton>
        </SliderContainer>
      </TextContainer>
    </MainContainer>
  );
};

export default Slider;