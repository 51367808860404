import React from "react";
import Navbar from "../../Header/Navbar/Navbar";
import CF1 from "../../Frames/ContuctFrame/CF1/CF1";
import Footer from "../../Footer/Footer";
import CF2 from "../../Frames/ContuctFrame/CF2/CF2";
import Banner from "../../Banner/Banner";

const Contuct_us = () => {
  return (
    <>
      <Banner />
      <Navbar />
      <CF1 />
      <CF2 />
      <Footer />
    </>
  );
};

export default Contuct_us;
