// styled-components kütüphanesini import ediyoruz.
import styled from "styled-components";

// Ana konteyneri tanımlıyoruz.
const MainContainer = styled.div`
  width: 300px; /* Konteynerin genişliği 340px olarak ayarlandı */
  display: flex; /* Flexbox düzeni kullanılarak içeriğin hizalanması sağlanıyor */
  height: 120vh; /* Konteynerin yüksekliği, görünüm yüksekliğinin 150 katı olarak ayarlandı */
  flex-direction: column; /* Flexbox düzeninde içeriğin sütun olarak hizalanmasını sağlıyor */
  justify-content: start; /* İçeriğin üst kısımdan başlamasını sağlar */
  align-items: start; /* İçeriğin soldan hizalanmasını sağlar */
  padding-top: 5px; /* Üstten 15px boşluk bırakır */
  gap: 5px; /* Elemanlar arasındaki boşluğu 15px olarak ayarlar */
  background-color: white; /* Arka plan rengini beyaz olarak ayarlar */
  
  @media (max-width: 800px) {
    display: ${({ showSideBar }) => (showSideBar ? "flex" : "none")}; /* Ekran genişliği 800px ve altındaysa showSideBar durumuna göre görünürlüğü ayarlar */
    position: absolute; /* Konteyneri mutlak konumda yerleştirir */
  }
  
  @media (max-width: 350px) {
    width: 300px; /* Ekran genişliği 350px ve altındaysa genişliği 300px olarak ayarlar */
  }
`;

// Liste konteynerini tanımlıyoruz.
const ListContainer = styled.div`
  width: 240px; /* Konteynerin genişliği 280px olarak ayarlandı */
  min-height: 40px; /* Minimum yüksekliği 50px olarak ayarlandı */
  display: flex; /* Flexbox düzeni kullanılarak içeriğin hizalanması sağlanıyor */
  align-items: center; /* İçeriği dikey olarak ortalar */
  justify-content: start; /* İçeriği soldan hizalar */
  font-size: 16px; /* Yazı boyutunu 18px olarak ayarlar */
  font-weight: 500; /* Yazı kalınlığını 500 olarak ayarlar */
  margin: 0%; /* Margin değerini sıfırlar */
  gap: 5px; /* Elemanlar arasındaki boşluğu 5px olarak ayarlar */
  padding-left: 30px; /* Soldan 30px boşluk bırakır */
  cursor: pointer; /* İmleci pointer (yani el şeklinde) yapar */
  color: black; /* Yazı rengini siyah olarak ayarlar */
  text-decoration: none; /* Yazı altını çizer (varsa) kaldırır */
  
  &:hover {
    background-color: #7b2cbf; /* Üzerine gelindiğinde arka plan rengini değiştirir */
    color: white; /* Üzerine gelindiğinde yazı rengini beyaz olarak değiştirir */
  }
  
  & > a {
    text-decoration: none; /* Linklerin altını çizmesini kaldırır */
    color: black; /* Linklerin yazı rengini siyah yapar */
    
    &:hover {
      color: white; /* Üzerine gelindiğinde linklerin yazı rengini beyaz yapar */
    }
  }
  
  @media (max-width: 350px) {
    width: 280px; /* Ekran genişliği 350px ve altındaysa genişliği 280px olarak ayarlar */
    padding-left: 10px; /* Soldan 10px boşluk bırakır */
  }
`;

// Diğer liste konteynerini tanımlıyoruz.
const List2Container = styled.div`
  display: ${({ showMenu }) => (showMenu ? "flex" : "none")}; /* showMenu durumuna göre görünürlüğü ayarlar */
  flex-direction: column; /* Flexbox düzeninde içeriğin sütun olarak hizalanmasını sağlar */
  align-items: center; /* İçeriği dikey olarak ortalar */
  justify-content: start; /* İçeriği üstten hizalar */
`;

// Liste öğelerini tanımlıyoruz.
const List = styled.div`
  width: 270px; /* Konteynerin genişliği 270px olarak ayarlandı */
  min-height: 50px; /* Minimum yüksekliği 50px olarak ayarlandı */
  padding-left: 35px; /* Soldan 35px boşluk bırakır */
  display: flex; /* Flexbox düzeni kullanılarak içeriğin hizalanması sağlanıyor */
  align-items: center; /* İçeriği dikey olarak ortalar */
  justify-content: start; /* İçeriği soldan hizalar */
  font-size: 18px; /* Yazı boyutunu 18px olarak ayarlar */
  font-weight: 500; /* Yazı kalınlığını 500 olarak ayarlar */
  cursor: pointer; /* İmleci pointer (yani el şeklinde) yapar */
  margin: 0%; /* Margin değerini sıfırlar */
  gap: 5px; /* Elemanlar arasındaki boşluğu 5px olarak ayarlar */
  color: black; /* Yazı rengini siyah olarak ayarlar */
  
  &:hover {
    background-color: #7b2cbf; /* Üzerine gelindiğinde arka plan rengini değiştirir */
    color: white; /* Üzerine gelindiğinde yazı rengini beyaz olarak değiştirir */
    
    a {
      color: white; /* Üzerine gelindiğinde içindeki linklerin yazı rengini beyaz yapar */
    }
  }
  
  & > a {
    text-decoration: none; /* Linklerin altını çizmesini kaldırır */
  }
`;

// Aşağıya çekme butonunu tanımlıyoruz.
const DragDown = styled.div`
  font-size: 24px; /* Yazı boyutunu 24px olarak ayarlar */
  display: flex; /* Flexbox düzeni kullanılarak içeriğin hizalanması sağlanıyor */
  align-items: center; /* İçeriği dikey olarak ortalar */
  justify-content: center; /* İçeriği yatay olarak ortalar */
  font-weight: 600; /* Yazı kalınlığını 600 olarak ayarlar */
`;

// Konteynerleri düzenli bir şekilde yerleştirmek için tanımlıyoruz.
const Container = styled.div`
  display: flex; /* Flexbox düzeni kullanılarak içeriğin hizalanması sağlanıyor */
  flex-wrap: wrap; /* İçerik taşarsa satır başına geçmesini sağlar */
  gap: 10px; /* Elemanlar arasındaki boşluğu 10px olarak ayarlar */
  justify-content: space-between; /* İçeriği eşit aralıklarla hizalar */
`;

// Sözleşme kutusunu tanımlıyoruz.
const ContractBox = styled.div`
  width: 22%; /* Kutunun genişliğini yüzde 22 olarak ayarlar */
  min-height: 150px; /* Minimum yüksekliği 150px olarak ayarlandı */
  border: 1px solid #ddd; /* Kutunun etrafına 1px kalınlığında açık gri renkte bir sınır ekler */
  border-radius: 10px; /* Köşeleri 10px yuvarlatır */
  padding: 10px; /* İçeriden 10px boşluk bırakır */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Kutunun etrafına hafif bir gölge ekler */
  position: relative; /* Konteynerin konumunu göreli olarak ayarlar */
  background: white; /* Arka plan rengini beyaz olarak ayarlar */
  overflow: hidden; /* Taşan içeriği gizler */
  
  &:hover .actions {
    display: block; /* Üzerine gelindiğinde 'actions' sınıfına sahip elemanları gösterir */
  }
`;

// Eylem butonlarını tanımlıyoruz.
const ActionButton = styled.button`
  display: none; /* Varsayılan olarak butonları gizler */
  position: absolute; /* Konumu mutlak olarak ayarlar */
  bottom: 10px; /* Alt kenardan 10px boşluk bırakır */
  left: 50%; /* Sol kenardan %50 konumlandırır */
  transform: translateX(-50%); /* Butonu yatayda ortalar */
  background: #4b0082; /* Arka plan rengini koyu mor olarak ayarlar */
  color: white; /* Yazı rengini beyaz olarak ayarlar */
  border: none; /* Kenar çizgisini kaldırır */
  padding: 5px 10px; /* İçeriden 5px üst ve alt, 10px sağ ve soldan boşluk bırakır */
  border-radius: 5px; /* Köşeleri 5px yuvarlatır */
  cursor: pointer; /* İmleci pointer (yani el şeklinde) yapar */
  
  &.download {
    background: #007bff; /* 'download' sınıfı varsa arka plan rengini mavi olarak değiştirir */
  }
`;

export { MainContainer, ListContainer, List2Container, List, DragDown, Container, ContractBox, ActionButton };
