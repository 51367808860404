import React from "react";
import { BannerContainer } from "./StyledBanner";

const Banner = () => {
  return (
    <BannerContainer>
      Bu site şu anda sadece avukatların üyeliğine açıktır. Emlak ilanlarının
      girişi için ayrı bir duyuru yapılacaktır.
    </BannerContainer>
  );
};

export default Banner;
