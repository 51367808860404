import styled from "styled-components"; // styled-components kütüphanesini import eder, bu kütüphane React bileşenleri için stiller oluşturmayı sağlar.

export const MainContainer = styled.div`
  width: 100%; // Ana konteynerin genişliğini %100 olarak ayarlar.
  display: flex; // Flexbox düzenini kullanır.
  align-items: start; // İçerikleri üstte hizalar.
  justify-content: start; // İçerikleri sola hizalar.
  background-color: #eef1f9; // Arka plan rengini açık mavi olarak ayarlar.
  gap: 2%; // Flex öğeleri arasına %15 boşluk bırakır.
`;

export const CardContain = styled.div`
  padding: 50px 0px; // Üst ve alt boşlukları 50px, sağ ve sol boşlukları 0px olarak ayarlar.
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  flex-wrap: wrap; // İçeriklerin bir satıra sığmıyorsa bir sonraki satıra geçmesini sağlar.
`;

export const CardContainer = styled.div`
  display: flex; // Flexbox düzenini kullanır.
  flex-direction: column; // İçerikleri dikey olarak sıralar.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  gap: 15px; // Flex öğeleri arasına 30px boşluk bırakır.
`;

export const Humburger = styled.div`
  font-size: 36px; // Yazı boyutunu 50px olarak ayarlar.
  color: white; // Yazı rengini beyaz yapar.
  display: none; // Varsayılan olarak gizler.
  @media (max-width: 800px) { // Ekran genişliği 800px veya daha küçükse:
    display: flex; // Flexbox düzenini kullanarak görünür hale getirir.
    position: absolute; // Mutlak konumlandırma kullanır.
    top: 8px; // Üstten 8px uzaklıkta konumlandırır.
    left: 20px; // Soldan 20px uzaklıkta konumlandırır.
  }
`;

export const Heading = styled.h1`
  font-size: 20px; // Yazı boyutunu 28px olarak ayarlar.
  display: flex; // Flexbox düzenini kullanır.
  font-weight: 700; // Yazı kalınlığını 700 olarak ayarlar.
  margin: 0%; // Marginleri sıfırlar.
  width: 700px; // Genişliği 800px olarak ayarlar.
  color: black; // Yazı rengini siyah yapar.
`;

export const SearchHolder = styled.div`
  /* width: 800px; */
  border-radius: 8px; // Köşeleri 8px yuvarlatır.
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  flex-wrap: wrap; // İçeriklerin bir satıra sığmıyorsa bir sonraki satıra geçmesini sağlar.
  gap: 20px; // Flex öğeleri arasına 20px boşluk bırakır.
  padding: 20px; // İçerinin etrafında 20px boşluk bırakır.
  background-color: white; // Arka plan rengini beyaz yapar.
  @media (max-width: 1400px) { // Ekran genişliği 1400px veya daha küçükse:
    width: 400px; // Genişliği 600px olarak ayarlar.
  }
`;

export const SearchContainer = styled.div`
  background-color: #eef1f9; // Arka plan rengini açık mavi yapar.
  width: 300px; // Genişliği 320px olarak ayarlar.
  height: 45px; // Yüksekliği 45px olarak ayarlar.
  border-radius: 6px; // Köşeleri 6px yuvarlatır.
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: space-between; // İçerikleri aralarına boşluk bırakarak hizalar.
  padding-left: 50px; // Sol tarafta 20px iç boşluk bırakır.
`;

export const SearchInput = styled.input`
  width: 240px; // Genişliği 260px olarak ayarlar.
  height: 45px; // Yüksekliği 45px olarak ayarlar.
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  border: none; // Kenarlıkları kaldırır.
  background-color: #eef1f9; // Arka plan rengini açık mavi yapar.
  &:focus { // Odaklandığında:
    outline: none; // Kenarlık görünümünü kaldırır.
    border: none; // Kenarlıkları kaldırır.
  }
  &:active { // Tıklama durumunda:
    outline: none; // Kenarlık görünümünü kaldırır.
  }
`;

export const SearchIcon = styled.div`
  width: 40px; // Genişliği 40px olarak ayarlar.
  height: 45px; // Yüksekliği 45px olarak ayarlar.
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  font-size: 20px; // Yazı boyutunu 20px olarak ayarlar.
  font-weight: 600; // Yazı kalınlığını 600 olarak ayarlar.
  border-radius: 0px 6px 6px 0px; // Sağ köşeleri yuvarlatır.
  color: white; // Yazı rengini beyaz yapar.
  background-color: #7b2cbf; // Arka plan rengini mor yapar.
`;

export const ArrowIcon = styled.div`
  width: 40px; // Genişliği 40px olarak ayarlar.
  height: 45px; // Yüksekliği 45px olarak ayarlar.
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  font-size: 20px; // Yazı boyutunu 20px olarak ayarlar.
  font-weight: 600; // Yazı kalınlığını 600 olarak ayarlar.
  border-radius: 0px 6px 6px 0px; // Sağ köşeleri yuvarlatır.
  color: white; // Yazı rengini beyaz yapar.
  background-color: #7b2cbf; // Arka plan rengini mor yapar.
`;

export const FilterContainer = styled.div`
  width: 120px; // Genişliği 150px olarak ayarlar.
  height: 36px; // Yüksekliği 45px olarak ayarlar.
  border-radius: 6px; // Köşeleri 6px yuvarlatır.
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  border: 1px solid #7b2cbf; // Kenarları 1px mor renkli bir çizgiyle çizer.
  background-color: white; // Arka plan rengini beyaz yapar.
  padding-left: 5px; // Sol tarafta 5px iç boşluk bırakır.
`;

export const Filter = styled.select`
  width: 110px; // Genişliği 110px olarak ayarlar.
  height: 36px; // Yüksekliği 45px olarak ayarlar.
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  border: none; // Kenarlıkları kaldırır.
  appearance: none; // Tarayıcı varsayılan seçici görünümünü kaldırır.
  background-color: white; // Arka plan rengini beyaz yapar.
  option { // Seçenekler için stiller:
    width: 100px; // Genişliği 150px olarak ayarlar.
    min-height: 36px; // Minimum yüksekliği 45px olarak ayarlar.
    background-color: white; // Arka plan rengini beyaz yapar.
  }
  &:focus { // Odaklandığında:
    outline: none; // Kenarlık görünümünü kaldırır.
    border: none; // Kenarlıkları kaldırır.
  }
  &:active { // Tıklama durumunda:
    outline: none; // Kenarlık görünümünü kaldırır.
  }
`;

export const LawyerContainer = styled.div`
  width: 800px; // Genişliği 800px olarak ayarlar.
  min-height: 80px; // Minimum yüksekliği 80px olarak ayarlar.
  border-radius: 8px; // Köşeleri 8px yuvarlatır.
  padding: 10px; // İçerinin etrafında 20px boşluk bırakır.
  display: flex; // Flexbox düzenini kullanır.
  align-items: start; // İçerikleri üstte hizalar.
  justify-content: start; // İçerikleri sola hizalar.
  background-color: white; // Arka plan rengini beyaz yapar.
  flex-direction: column; // İçerikleri dikey olarak sıralar.
  gap: 20px; // Flex öğeleri arasına 30px boşluk bırakır.
`;

export const UpperContainer = styled.div`
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: space-between; // İçerikleri aralarına boşluk bırakarak hizalar.
  width: 680px; // Genişliği 780px olarak ayarlar.
`;

export const UpperTextContainer = styled.div`
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  gap: 15px; // Flex öğeleri arasına 15px boşluk bırakır.
`;

export const LowerContainer = styled.div`
  display: flex; // Flexbox düzenini kullanır.
  align-items: start; // İçerikleri üstte hizalar.
  justify-content: start; // İçerikleri sola hizalar.
  gap: 5px; // Flex öğeleri arasına 5px boşluk bırakır.
  flex-direction: column; // İçerikleri dikey olarak sıralar.
`;

export const ProfilePic = styled.img`
  width: 80px; // Genişliği 80px olarak ayarlar.
  height: 80px; // Yüksekliği 80px olarak ayarlar.
`;

export const TextContainer = styled.div`
  display: flex; // Flexbox düzenini kullanır.
  align-items: start; // İçerikleri üstte hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  flex-direction: column; // İçerikleri dikey olarak sıralar.
  max-width: 400px; // Maksimum genişliği 400px olarak ayarlar.
  gap: 5px; // Flex öğeleri arasına 5px boşluk bırakır.
`;

export const TextHolder = styled.div`
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: start; // İçerikleri sola hizalar.
  gap: 5px; // Flex öğeleri arasına 15px boşluk bırakır.
`;

export const NameContainer = styled.h1`
  font-size: 16px; // Yazı boyutunu 18px olarak ayarlar.
  font-weight: 400; // Yazı kalınlığını 600 olarak ayarlar.
  margin: 0%; // Marginleri sıfırlar.
`;

export const IconContainer = styled.div`
  font-size: 20px; // Yazı boyutunu 20px olarak ayarlar.
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  color: red; // Yazı rengini kırmızı yapar.
`;

export const TextContainer1 = styled.div`
  display: flex; // Flexbox düzenini kullanır.
  align-items: start; // İçerikleri üstte hizalar.
  justify-content: start; // İçerikleri sola hizalar.
  gap: 5px; // Flex öğeleri arasına 5px boşluk bırakır.
`;

export const TextContainer2 = styled.div`
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  gap: 5px; // Flex öğeleri arasına 5px boşluk bırakır.
  color: #7b2cbf; // Yazı rengini mor yapar.
`;

export const Title = styled.h1`
  font-size: 12px; // Yazı boyutunu 14px olarak ayarlar.
  font-weight: 400; // Yazı kalınlığını 600 olarak ayarlar.
  margin: 0%; // Marginleri sıfırlar.
`;

export const Item = styled.h1`
  font-size: 14px; // Yazı boyutunu 14px olarak ayarlar.
  font-weight: 500; // Yazı kalınlığını 500 olarak ayarlar.
  margin: 0%; // Marginleri sıfırlar.
`;

export const ButtonContainer = styled.div`
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  gap: 20px; // Flex öğeleri arasına 20px boşluk bırakır.
`;

export const Button1 = styled.button`
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  gap: 8px; // Flex öğeleri arasına 8px boşluk bırakır.
  border: none; // Kenarlıkları kaldırır.
  color: green; // Yazı rengini yeşil yapar.
  width: 100px; // Genişliği 100px olarak ayarlar.
  height: 40px; // Yüksekliği 40px olarak ayarlar.
  background-color: white; // Arka plan rengini beyaz yapar.
  cursor: pointer; // Fare üzerine geldiğinde imleci işaretçi yapar.
`;

export const Button2 = styled.button`
  display: flex; // Flexbox düzenini kullanır.
  align-items: center; // İçerikleri ortada hizalar.
  justify-content: center; // İçerikleri ortada hizalar.
  gap: 8px; // Flex öğeleri arasına 8px boşluk bırakır.
  border: none; // Kenarlıkları kaldırır.
  width: 100px; // Genişliği 100px olarak ayarlar.
  height: 40px; // Yüksekliği 40px olarak ayarlar.
  background-color: white; // Arka plan rengini beyaz yapar.
  color: red; // Yazı rengini kırmızı yapar.
  cursor: pointer; // Fare üzerine geldiğinde imleci işaretçi yapar.
`;
