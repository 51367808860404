import React, { useState, useEffect } from "react";
import {
  MainContainer,
  ListContainer,
  List2Container,
  List,
  DragDown,
} from "./StyledLawyerSidebar";
import { FaArrowRight, FaHome, FaRegCheckCircle } from "react-icons/fa";
import { BsChatHeart } from "react-icons/bs";
import { TiGroup } from "react-icons/ti";
import { IoPerson } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import { IoIosArrowDown } from "react-icons/io";

const WelcomePage = ({ lawyerName }) => (
  <div style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#F0F8FF',
    padding: '20px',
    textAlign: 'center',
  }}>
    <h1 style={{ color: '#4B0082', fontWeight: 'bold', fontSize: '2rem', marginBottom: '20px' }}>
      Hoş geldiniz sayın avukat {lawyerName}
    </h1>
    <p style={{ fontSize: '1.2rem', lineHeight: '1.6', maxWidth: '800px' }}>
      guvenlisatkirala.com içinde yayınlanan tüm ilanların bir avukatı olmak zorundadır. Bu web sitesinde sorumluluklarınız:
    </p>
    <ol style={{ textAlign: 'left', fontSize: '1.1rem', lineHeight: '1.5', maxWidth: '800px' }}>
      <li>İlan sahiplerinin ilanlarında ki bilgilerin doğruluğunu sözleşmeyle onaylamak.</li>
      <li>Sizi seçen ilan sahibi ile sözleşmeler yapmak.</li>
      <li>Vekâletini aldığınız ilan satılır veya kiraya verildiğinde taraflar arasındaki sözleşmeye sadece şahit olarak imza atmak ve "sözleşmeler" kısmına bunu yüklemek.</li>
    </ol>
    <p style={{ fontSize: '1.2rem', marginTop: '20px', maxWidth: '800px' }}>
      Satılık ilan için satış bedelinin binde 4'ü ve kiralanan ilanın kira bedelinin yüzde 20'si sizindir.
    </p>
    <p style={{ fontSize: '1.2rem', marginTop: '20px', maxWidth: '800px' }}>
      
    </p>
  </div>
);

const BanaVekaletNameVerenUyeler = () => (
  <div>
    <h2>Bana vekaletname veren üyeler</h2>
    {/* Bu bileşenin içeriği */}
  </div>
);



const WebSiteSozlesmeleri = () => {
  const [files, setFiles] = useState(() => {
    const savedFiles = localStorage.getItem('websiteFiles');
    return savedFiles ? JSON.parse(savedFiles) : [];
  });

  useEffect(() => {
    localStorage.setItem('websiteFiles', JSON.stringify(files));
  }, [files]);

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files).map(file => ({
      name: file.name.replace('.docx', '').replace('.doc', ''),
      url: URL.createObjectURL(file)
    }));
    setFiles(prevFiles => [...prevFiles, ...uploadedFiles].slice(0, 3)); // 3 dosya limiti
  };

  const handleFileDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    link.click();
  };

  return (
    <div style={{ padding: '20px', position: 'relative' }}>
      {files.length < 3 && (
        <input
          type="file"
          onChange={handleFileUpload}
          multiple
          accept=".doc, .docx"
          style={{
            marginBottom: '20px',
            display: 'block',
            textAlign: 'center',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
            backgroundColor: '#f0f0f0',
            border: '2px solid #ccc',
            borderRadius: '8px',
            padding: '10px',
          }}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            handleFileUpload(e);
          }}
        />
      )}
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        justifyContent: 'flex-start',
      }}>
        {files.map((file, index) => (
          <div key={index} style={{
            border: '1px solid #ccc',
            padding: '20px',
            borderRadius: '8px',
            textAlign: 'center',
            backgroundColor: '#f9f9f9',
            width: '100%', // Konteynır genişliği %100
            boxSizing: 'border-box',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, background-color 0.3s ease',
            position: 'relative',
            transform: 'translateY(0)',
            opacity: 1,
            animation: 'fadeInUp 0.5s ease-out'
          }}
            className="file-container"
          >
            <h3 style={{
              fontSize: '1.1rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>{file.name}</h3>
            <button
              onClick={() => handleFileDownload(file)}
              style={{
                marginTop: '10px',
                padding: '10px 20px',
                borderRadius: '4px',
                border: 'none',
                backgroundColor: '#007bff',
                color: '#fff',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
              }}
            >
              Dosyayı İndir
            </button>
          </div>
        ))}
      </div>
      <style>
        {`
          .file-container {
            animation: fadeInUp 0.5s ease-out;
          }

          .file-container:hover {
            transform: scale(1.05) translateY(-5px);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
            background-color: #e9ecef;
          }

          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translateY(20px);
            }
            to {
              opacity: 1;
              transform: translateY(0);
            }
          }
        `}
      </style>
    </div>
  );
};


const SatilikIlanSozlesmeleri = () => {
  const [files, setFiles] = useState(() => {
    const savedFiles = localStorage.getItem('satilikFiles');
    return savedFiles ? JSON.parse(savedFiles) : [];
  });

  useEffect(() => {
    localStorage.setItem('satilikFiles', JSON.stringify(files));
  }, [files]);

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files).map(file => ({
      id: Date.now() + Math.random().toString(36).substr(2, 9), // unique id
      name: file.name,
      url: URL.createObjectURL(file),
      date: new Date().toLocaleString() // oluşturulma tarihi
    }));
    setFiles(prevFiles => [...prevFiles, ...uploadedFiles]);
  };

  const handleFileDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    link.click();
  };

  return (
    <div style={{ padding: '20px' }}>
      <input
        type="file"
        onChange={handleFileUpload}
        multiple
        style={{
          marginBottom: '20px',
          display: 'block',
          textAlign: 'center'
        }}
      />
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        justifyContent: 'flex-start'
      }}>
        {files.map((file) => (
          <div key={file.id} style={{
            border: '1px solid #ccc',
            padding: '20px',
            borderRadius: '8px',
            textAlign: 'center',
            backgroundColor: '#f9f9f9',
            width: 'calc(25% - 20px)', // Konteynır boyutu %25, yani 4 tanesi yan yana
            boxSizing: 'border-box',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            position: 'relative'
          }}>
            <h3 style={{
              fontSize: '1.1rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>{file.name}</h3>
            <p style={{
              fontSize: '0.9rem',
              color: '#666'
            }}>Yüklenme Tarihi: {file.date}</p>
            <button
              onClick={() => handleFileDownload(file)}
              style={{
                marginBottom: '10px',
                padding: '10px 20px',
                borderRadius: '4px',
                border: 'none',
                backgroundColor: '#007bff',
                color: '#fff',
                cursor: 'pointer'
              }}
            >
              Dosyayı İndir
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

const KiralikIlanSozlesmeleri = () => {
  const [files, setFiles] = useState(() => {
    const savedFiles = localStorage.getItem('kiralikFiles');
    return savedFiles ? JSON.parse(savedFiles) : [];
  });

  useEffect(() => {
    localStorage.setItem('kiralikFiles', JSON.stringify(files));
  }, [files]);

  const handleFileUpload = (e) => {
    const uploadedFiles = Array.from(e.target.files).map(file => ({
      id: Date.now() + Math.random().toString(36).substr(2, 9), // unique id
      name: file.name,
      url: URL.createObjectURL(file),
      date: new Date().toLocaleString() // oluşturulma tarihi
    }));
    setFiles(prevFiles => [...prevFiles, ...uploadedFiles]);
  };

  const handleFileDownload = (file) => {
    const link = document.createElement('a');
    link.href = file.url;
    link.download = file.name;
    link.click();
  };

  return (
    <div style={{ padding: '20px' }}>
      <input
        type="file"
        onChange={handleFileUpload}
        multiple
        style={{
          marginBottom: '20px',
          display: 'block',
          textAlign: 'center'
        }}
      />
      <div style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        justifyContent: 'flex-start'
      }}>
        {files.map((file) => (
          <div key={file.id} style={{
            border: '1px solid #ccc',
            padding: '20px',
            borderRadius: '8px',
            textAlign: 'center',
            backgroundColor: '#f9f9f9',
            width: 'calc(25% - 20px)', // Konteynır boyutu %25, yani 4 tanesi yan yana
            boxSizing: 'border-box',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
            position: 'relative'
          }}>
            <h3 style={{
              fontSize: '1.1rem',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>{file.name}</h3>
            <p style={{
              fontSize: '0.9rem',
              color: '#666'
            }}>Yüklenme Tarihi: {file.date}</p>
            <button
              onClick={() => handleFileDownload(file)}
              style={{
                marginBottom: '10px',
                padding: '10px 20px',
                borderRadius: '4px',
                border: 'none',
                backgroundColor: '#007bff',
                color: '#fff',
                cursor: 'pointer'
              }}
            >
              Dosyayı İndir
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};


const UyelikPaketleri = () => (
  <div>
    <h2>Üyelik Paketlerim</h2>
    <div style={{
      display: 'flex',
      justifyContent: 'space-between',
      gap: '20px'
    }}>
      <div style={{
        border: '1px solid #ccc',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        backgroundColor: '#fff',
        flex: '1'
      }}>
        <p>Bir aylık üyelik paketi 600 lira</p>
        <button>Ödeme Yap</button>
      </div>
      <div style={{
        border: '1px solid #ccc',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
        backgroundColor: '#fff',
        flex: '1'
      }}>
        <p>Bir yıllık üyelik paketi 3600 lira</p>
        <button>Ödeme Yap</button>
      </div>
    </div>
  </div>
);

const LawyerSidebar = ({ showSideBar, lawyerName }) => {
  const [openMenu, setOpenMenu] = useState(null);
  const [showWelcome, setShowWelcome] = useState(true);
  const [currentPage, setCurrentPage] = useState("welcome");

  useEffect(() => {
    setShowWelcome(true);
    setCurrentPage("welcome");
  }, [showSideBar]);

  const toggleMenu = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };

  const handleAnaSayfaClick = (e) => {
    e.preventDefault();
    setShowWelcome(true);
    setCurrentPage("welcome");
  };

  const handlePageChange = (pageName) => {
    setShowWelcome(false);
    setCurrentPage(pageName);
  };

  return (
    <div style={{ display: 'flex', height: '100vh', width: '100vw' }}>
      <MainContainer showSideBar={showSideBar}>
        <ListContainer onClick={handleAnaSayfaClick}>
          <FaHome />
          Kişisel Ana Sayfa
        </ListContainer>
        <ListContainer onClick={() => handlePageChange("vekaletname")}>
          <IoPerson />
          Vekaletname İlanları
          <DragDown>
            <IoIosArrowDown
              onClick={(e) => {
                e.stopPropagation();
                toggleMenu("Vekaletname İlanları");
              }}
            />
          </DragDown>
        </ListContainer>
        <List2Container showMenu={openMenu === "Vekaletname İlanları"}>
          <List onClick={() => handlePageChange("aktifIlanlar")}>
            <FaArrowRight /> Aktif İlanlar
          </List>
          <List onClick={() => handlePageChange("pasifIlanlar")}>
            <FaArrowRight /> Pasif İlanlar
          </List>
          <List onClick={() => handlePageChange("suresiDolanIlanlar")}>
            <FaArrowRight /> Süresi Dolan İlanlar
          </List>
          <List onClick={() => handlePageChange("taslakIlanlar")}>
            <FaArrowRight /> Taslak İlanlar
          </List>
          <List onClick={() => handlePageChange("silinenIlanlar")}>
            <FaArrowRight /> Silinen İlanlar
          </List>
        </List2Container>
        <ListContainer onClick={() => handlePageChange("onayliIlanlar")}>
          <FaRegCheckCircle />
          Onaylı İlanlar
        </ListContainer>
        <ListContainer onClick={() => handlePageChange("sozlesmeler")}>
          <IoPerson />
          Sözleşmeler
          <DragDown>
            <IoIosArrowDown
              onClick={(e) => {
                e.stopPropagation();
                toggleMenu("Sözleşmeler");
              }}
            />
          </DragDown>
        </ListContainer>
        <List2Container showMenu={openMenu === "Sözleşmeler"}>
          <List onClick={() => handlePageChange("webSiteSozlesmeleri")}>
            <FaArrowRight /> Web Sitesi Sözleşmeleri
          </List>
          <List onClick={() => handlePageChange("satilikIlanSozlesmeleri")}>
            <FaArrowRight /> Satılık İlan Sözleşmeleri
          </List>
          <List onClick={() => handlePageChange("kiralikIlanSozlesmeleri")}>
            <FaArrowRight /> Kiralık İlan Sözleşmeleri
          </List>
        </List2Container>
        <ListContainer onClick={() => handlePageChange("vekaletVerenUyeler")}>
          <TiGroup />
          Vekalet Veren Üyeler
        </ListContainer>
        <ListContainer onClick={() => handlePageChange("mesajlar")}>
          <BsChatHeart />
          Mesajlar
        </ListContainer>
        <ListContainer onClick={() => toggleMenu("Üyeliğim")}>
          <CgProfile />
          Üyeliğim
          <DragDown>
            <IoIosArrowDown />
          </DragDown>
        </ListContainer>
        <List2Container showMenu={openMenu === "Üyeliğim"}>
          <List onClick={() => handlePageChange("uyelikBilgileri")}>
            <FaArrowRight /> Üyelik Bilgileri
          </List>
          <List onClick={() => handlePageChange("sifreDegistir")}>
            <FaArrowRight /> Şifre Değiştir
          </List>
          <List onClick={() => handlePageChange("uyelikPaketleri")}>
            <FaArrowRight /> Üyelik Paketleri
          </List>
          <List onClick={() => handlePageChange("odemeler")}>
            <FaArrowRight /> Ödemelerim
          </List>
        </List2Container>
      </MainContainer>
      <div
        style={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#F0F8FF',
          height: '100%',
          width: '100%',
          overflowY: 'auto',
        }}
      >
        {showWelcome ? (
          <WelcomePage lawyerName={lawyerName} />
        ) : (
          <div style={{ padding: '20px' }}>
            {currentPage === "vekaletname" && <BanaVekaletNameVerenUyeler />}
            {currentPage === "aktifIlanlar" && <div>Aktif İlanlar Sayfası</div>}
            {currentPage === "pasifIlanlar" && <div>Pasif İlanlar Sayfası</div>}
            {currentPage === "suresiDolanIlanlar" && <div>Süresi Dolan İlanlar Sayfası</div>}
            {currentPage === "taslakIlanlar" && <div>Taslak İlanlar Sayfası</div>}
            {currentPage === "silinenIlanlar" && <div>Silinen İlanlar Sayfası</div>}
            {currentPage === "onayliIlanlar" && <div>Onaylı İlanlar Sayfası</div>}
            {currentPage === "webSiteSozlesmeleri" && <WebSiteSozlesmeleri />}
            {currentPage === "satilikIlanSozlesmeleri" && <SatilikIlanSozlesmeleri />}
            {currentPage === "kiralikIlanSozlesmeleri" && <KiralikIlanSozlesmeleri />}
            {currentPage === "vekaletVerenUyeler" && <BanaVekaletNameVerenUyeler />}
            {currentPage === "mesajlar" && <div>Mesajlar Sayfası</div>}
            {currentPage === "uyelikBilgileri" && <div>Üyelik Bilgileri Sayfası</div>}
            {currentPage === "sifreDegistir" && <div>Şifre Değiştir Sayfası</div>}
            {currentPage === "uyelikPaketleri" && <UyelikPaketleri />}
            {currentPage === "odemeler" && <div>Ödemelerim Sayfası</div>}
          </div>
        )}
      </div>
    </div>
  );
};

export default LawyerSidebar;


